import NavBar from './components/NavBar';
import { Box } from '@material-ui/core';
import BodyContent1 from './components/Content/BodyContent1';
import HeaderBox from './components/HeaderBox';
import BodyContent2 from './components/Content/BodyContent2';
import BodyContent3 from './components/Content/BodyContent3';
import Footer from './components/Footer';

function App() {
  return (
    <Box>
      <NavBar />
      <HeaderBox />
      <BodyContent1 />
      <BodyContent2 />
      <BodyContent3 />
      <Footer />
    </Box>
  );
}

export default App;
