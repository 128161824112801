import React from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import logo from '../images/logo.png'

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'relative',
        background: 'var(--clr-secondary)',
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        padding: '2rem 0',
        '& *': {
            color: 'white',
        },
        '& h3': {
            marginTop: '0',
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    logo: {
        width: '150px',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
        [theme.breakpoints.down('xs')]: {
            width: '120px',
        }
    }
}))

const Footer = () => {
    const classes = useStyles()

    return (
        <Box className={classes.footer}>
            <Container>
                <img className={classes.logo} src={logo} alt="logo" />
                <p style={{ marginBottom: '4rem' }}>2557 Rue 1647, Kowegko/Cotonou/Bénin | +229 90 84 00 84 | +229 90 84 00 84</p>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={3}>
                        <h3><span style={{ color: 'var(--clr-primary)' }}>Nos partenaires</span></h3>
                        <p>Zed Construction</p>
                        <p>NCA Groupe</p>
                        <p>Electrorass</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <h3><span style={{ color: 'var(--clr-primary)' }}>Nos activités</span></h3>
                        <p>Ressources humaine</p>
                        <p>Transport </p>
                        <p>Logistique </p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <h3><span style={{ color: 'var(--clr-primary)' }}>Service réclamation</span></h3>
                        <p>+229 97 23 42 55</p>
                        <p>Kowégbo/Cotonou</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <h3><span style={{ color: 'var(--clr-primary)' }}>E-commerce</span></h3>
                        <p>Electricité</p>
                        <p>Tissus</p>
                        <p>Divers</p>
                    </Grid>
                </Grid>
                <p style={{ marginTop: '4rem' }}>© Copyright ServicesFAR 2023</p>
            </Container>
        </Box>
    )
}

export default Footer