import React, { useRef } from 'react'
import { Box, Container, makeStyles } from '@material-ui/core'
import MenuItems from './MenuItems'

import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import lang from '../static/lang.json'

import banner1 from '../images/banner1.jpg'
import banner2 from '../images/banner2.jpg'
import banner3 from '../images/banner3.jpg'
import banner4 from '../images/banner4.jpg'


const useStyles = makeStyles((theme) => ({
    headerBox: {
        position: 'relative',
        marginBottom: '7rem',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '5rem',
        }
    },
    header: {
        height: '400px',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
    },
    headerTitle: {
        position: 'absolute',
        color: 'white',
        padding: '1rem',
        textShadow: '1px 1px 20px black',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            width: '70%',
        }
    },
    headerMenuItemBox: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: '-2rem',
            left: '50%',
            transform: 'translate(-50%)',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '2rem'
        }
    }
}))

const images = [
    {
        "title": lang.header.title,
        "subtitle": lang.header.subtitle,
        // "link": "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "link": banner1
    },
    {
        "title": "Distribution de colis et de courriers",
        "subtitle": "Nous réceptionnons et livrons à qui de droit au Bénin",
        // "link": "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        "link": banner2
    },
    {
        "title": "Service immobilier",
        "subtitle": "Faites payer vos locataires et recevez une notification instantanée",
        // "link": "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "link": banner3
    },
    {
        "title": "Ressource humaine",
        "subtitle": "Trouver un ouvrier à partir de 600 XOF l'heure",
        // "link": "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        "link": banner4
    },
];

const HeaderBox = () => {
    const slideRef = useRef(null)
    const classes = useStyles()

    return (
        <Box className={classes.headerBox}>
            <Zoom scale={1.4} ref={slideRef}>
                {images.map((each, index) => (
                    <div key={index} className={classes.header}>
                        <h1 className={classes.headerTitle}>{each.title}<br />{each.subtitle}</h1>
                        <img style={{ zIndex: 0, objectFit: "cover", width: "100%", height: "100%" }} alt="Slide" src={each.link} />
                    </div>
                ))}
            </Zoom>
            <Container className={classes.headerMenuItemBox}>
                <MenuItems slideRef={slideRef} />
            </Container>
        </Box>
    )
}

export default HeaderBox