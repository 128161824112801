import React from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import localisationIcon from '../images/localisation.svg'
import { itemListCollections } from '../static/collections'

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        gap: '1rem',
        padding: '.5rem 1rem',
        margin: '0 auto',
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
        }
    }
}))

const MenuItems = ({ slideRef }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={2} style={{ zIndex: 5 }}>
            {itemListCollections.map((item, index) => {
                return (
                    <Grid key={index} item xs={12} sm={3}>
                        <Paper className={classes.item} onClick={() => slideRef.current.goTo(index)}>
                            <img src={localisationIcon} alt="localisation" />
                            <p>{item}</p>
                        </Paper>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default MenuItems