import React from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import CustomButton from '../CustomButton'
import { bodyContent1ItemListCollections, globalCollections } from '../../static/collections'

import localisationIcon from '../../images/localisation-primary.svg'
import backgroundFrame from '../../images/background-frame.png'

const useStyles = makeStyles((theme) => ({
    bodyContent: {
        background: `url(${backgroundFrame})`,
        backgroundSize: 'cover',
        paddingBottom: '3.5rem'
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        gap: '1rem',
        marginBottom: '1.5rem',
        '& p': {
            margin: 0,
        }
    },
    title: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        }
    },
    itemTitle: {
        fontWeight: 'bold',
    }
}))

const BodyContent1 = () => {
    const classes = useStyles()

    return (
        <Container>
            <Box className={classes.bodyContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} style={{ marginBottom: '2rem' }}>
                        <h2 className={classes.title}>Pourquoi   <span style={{ color: 'var(--clr-primary)' }}>ServiceFAR ?</span> </h2>
                        <p>Le choix d'un fournisseur de transport et de logistique est crucial pour toute entreprise qui souhaite réussir sur le marché actuel. 
Avec une concurrence accrue et des attentes élevées des clients en matière de livraison rapide et fiable, il est important de trouver 
un partenaire de confiance qui peut répondre à vos besoins en matière de transport et de logistique. 
Nous sommes donc en mesure de comprendre vos besoins spécifiques et de vous offrir des solutions sur mesure qui répondent à vos exigences </p>
                        <CustomButton href={globalCollections.login}>Se connecter</CustomButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        {bodyContent1ItemListCollections.map((item, index) => {
                            return (
                                <Box key={index} className={classes.item}>
                                    <img src={localisationIcon} alt="localisation" />
                                    <Box>
                                        <p className={classes.itemTitle}>{item.title}</p>
                                        <p>{item.subtitle}</p>
                                    </Box>
                                </Box>
                            )
                        })}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default BodyContent1