import React from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import CustomButton from '../CustomButton'
import { globalCollections } from '../../static/collections'
import banner5 from '../../images/livraison.jpg'

const useStyles = makeStyles((theme) => ({
    bodyContent: {
        background: 'var(--clr-primary)',
        padding: '3rem 0',
        marginBottom: '5rem',
    },
    title: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        }
    },
    card: {
        background: 'white',
        height: '300px',
        width: '100%',
    }
}))

const BodyContent2 = () => {
    const classes = useStyles()

    return (
        <Box className={classes.bodyContent}>
            <Container>
                <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <Box className={classes.card}>
                        <img src={banner5} style={{width: '100%', objectFit:'contain', height: '100%'}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h2 className={classes.title}>Commandez et recevez <span style={{ color: 'white' }}>Oryx gaz</span> en moins de 48 heures</h2>
                        <h3>Indiquez le numéro de votre maison le numéro de la rue ou le nom de la rue et recevez le produit à Cotonou.</h3>
                        <CustomButton href={globalCollections.contact} color="black">Nous contacter</CustomButton>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default BodyContent2