/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    primaryBtn: {
        background: 'var(--clr-primary)',
        color: 'white',
        '&:hover': {
            background: 'var(--clr-primary)',
        }
    },
    secondaryBtn: {
        background: 'var(--clr-secondary)',
        color: 'white',
        '&:hover': {
            background: 'var(--clr-secondary)',
        }
    },
    blackBtn: {
        background: 'black',
        color: 'white',
        '&:hover': {
            background: 'black',
        }
    },
}))

const CustomButton = (props) => {
    const classes = useStyles()
    const { color } = props

    const changePage = () => {
        document.location.href = props.href
    }

    return (
        <>
            <Button onClick={props.href ? changePage : props.onClick} style={props.style} variant="contained" className={eval(`classes.${color ?? 'primary'}Btn`)}>
                {props.children}
            </Button>
        </>
    )
}

export default CustomButton