import React from 'react'
import { Box, Container, makeStyles } from '@material-ui/core'
import logo from '../images/logo.png'
import CustomButton from './CustomButton'
import { globalCollections } from '../static/collections'

const useStyles = makeStyles((theme) => ({
    navbar: {
        position: 'relative',
        height: '70px',
        background: 'var(--clr-primary)',
        [theme.breakpoints.down('xs')]: {
            height: '50px'
        }
    },
    navbarContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '1rem',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            '& *': {
                fontSize: '14px',
                padding: '.1rem .4rem',
                textTransform: 'capitalize',
            }
        }
    },
    logo: {
        zIndex: '1',
        position: 'absolute',
        bottom: 'calc(-100px + 56px)',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
        [theme.breakpoints.up('sm')]: {
            width: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80px',
            bottom: 'calc(-50px + 26px)'
        }
    }
}))

const NavBar = () => {
    const classes = useStyles()

    return (
        <Box className={classes.navbar}>
            <Container>
                <img className={classes.logo} src={logo} alt="logo" />
            </Container>
            <Container className={classes.navbarContent}>
                <CustomButton href={globalCollections.loyer} color="none">Loyer</CustomButton>
                <CustomButton href={globalCollections.connexion} color="secondary">Espace client</CustomButton>
            </Container>
        </Box>
    )
}

export default NavBar