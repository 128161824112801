import lang from './lang.json'

const globalCollections = {
    "connexion": "https://clients.servicesfar.com/public/index.php",
    "contact": "https://clients.servicesfar.com/public/contact.php",
    "loyer":  "https://clients.servicesfar.com/public/choixLocation.php",
    "login": "https://clients.servicesfar.com/public/login.php"
}

const itemListCollections = [
    lang.menu_items.item1,
    lang.menu_items.item2,
    lang.menu_items.item3,
    lang.menu_items.item4
]

const bodyContent1ItemListCollections = [
    {
        "title": "Nos solutions",
        "subtitle": " Que vous ayez besoin de transporter des produits à courte ou à longue distance, notre équipe de professionnels peut concevoir une solution adaptée à vos besoins. "
    },
    {
        "title": "Notre équipe",
        "subtitle": "Nos professionnels ont une connaissance approfondie de la logistique, des règlementations en matière de transport, de la gestion des stocks et de la distribution. Ils travaillent en étroite collaboration avec nos clients pour comprendre leurs besoins et concevoir des solutions efficaces pour répondre à leurs attentes."
    },
    {
        "title": "Des technologies de pointe",
        "subtitle": " Nous utilisons des systèmes de gestion de la chaîne d'approvisionnement de pointe pour suivre en temps réel le mouvement des produits."
    },
   
   
]

export {
    globalCollections,
    itemListCollections,
    bodyContent1ItemListCollections
}