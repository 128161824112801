import React from 'react'
import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import CustomButton from '../CustomButton'

import backgroundFrame from '../../images/background-frame.png'
import { globalCollections } from '../../static/collections'

const useStyles = makeStyles((theme) => ({
    bodyContent: {
        background: `url(${backgroundFrame})`,
        backgroundSize: 'cover',
        paddingBottom: '2rem'
    },
    title: {
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        }
    },
}))

const BodyContent3 = () => {
    const classes = useStyles()

    return (
        <Container>
            <Box className={classes.bodyContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={7} style={{ marginBottom: '2rem' }}>
                        <h2 className={classes.title}><span style={{ color: 'var(--clr-primary)' }}>ServiceFAR</span> pour gérer vos locations </h2>
                        <p>Via un lien de paiement vos locataires recevront instantanément leurs reçus. Vous recevrez le virement sur votre compte Momo et la notification dans votre boite mail </p>
                        <CustomButton href={globalCollections.login}>Se connecter</CustomButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default BodyContent3